<template>
  <div>
    <template v-if="stats.loading">
      <skeleton-loader
        class="mb-5 opacity-10 rounded-sm"
        height="30px"
        width="200px"
      />
      <div
        class="
          gap-8 grid grid-cols-1
          sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3
        "
      >
        <template v-for="index in 8">
          <skeleton-loader
            :key="index"
            class="opacity-10 rounded"
            height="100px"
            width="100%"
          />
        </template>
      </div>
    </template>

    <template v-else-if="stats.error">
      <div
        class="bg-white max-w-500px mx-auto pb-32 pt-20 px-10 rounded text-center"
      >
        <vector
          :src="assets.icons.sm.errorCircle"
          class="inline-flex justify-center mb-8"
          height="80"
          width="80"
        >
          <div class="bg-red-200 h-80px rounded-full w-80px" />
        </vector>

        <h3 class="font-bold mb-5 text-2xl">Unable to get Statistics</h3>

        <div class="font-light text-lg">
          {{ getFormError(stats) }}
        </div>

      </div>
    </template>
    <template v-else-if="groupable">
      <template v-for="(group, groupIndex) in groups">
        <div :key="groupIndex" class="mb-10">
          <h2 class="font-bold mb-5 text-xl">{{ group.title }}</h2>
          <div
            class="
              gap-8 grid grid-cols-1
              sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3
            "
          >
            <template v-for="(stat, statIndex) in group.stats">
              <div :key="statIndex" class="col-span-1">
                <stat :stat="stat" />
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div
        class="
          gap-8 grid grid-cols-1
          sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3
        "
      >
        <template v-for="(stat, statIndex) in stats.data">
          <div :key="statIndex" class="col-span-1">
            <stat :stat="stat" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: () => null,
    },
    groupable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    groups() {
      const units = this.stats?.data.map(stat => stat.unit)
        .filter((unit, index, array) => array.indexOf(unit) === index);
      return units.map(unit => ({
        title: unit,
        stats: this.stats?.data.filter(stat => stat.unit === unit),
      }));
    },
  },
}
</script>
